import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "クリエイターページに刻まれた「MEDLEY DESIGN & ENGINEERING」について紐解く",
  "date": "2018-11-22T09:19:39.000Z",
  "slug": "entry/2018/11/22/181939",
  "tags": ["medley"],
  "hero": "./2018_11_22.png",
  "heroAlt": "desgin & engineering"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。デザイナーマエダです。`}</p>
    <p>{`メドレーの`}<a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`クリエイターページ`}</a>{`を最近リニューアルしたのですが、ご覧いただけましたでしょうか。`}</p>
    <p>{`リニューアルまでの経緯は、デザインを担当した波切から後日このブログでエントリがあると思いますので詳細は割愛させていただきますが、リニューアル以前から「MEDLEY DESIGN & ENGINEERING」というサイトタイトルが使われているのは、みなさんご存じだったでしょうか。`}</p>
    <p>{`今回はこの「MEDLEY DESIGN & ENGINEERING」という言葉に秘められた意味について TechLunch で発表したのですが、事前に社内のエンジニアにヒアリングしてみたところ、この言葉の意味に気付いてない人もいたので、あらためて紐解いてみました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181122/20181122161427.png",
        "alt": "f:id:medley_inc:20181122161427p:plain",
        "title": "f:id:medley_inc:20181122161427p:plain"
      }}></img></p>
    <h1>{`クリエイティブ業界の最近の風潮`}</h1>
    <p>{`今年 5 月に経済産業省・特許庁が「`}<a parentName="p" {...{
        "href": "https://www.meti.go.jp/press/2018/05/20180523002/20180523002-1.pdf"
      }}>{`デザイン経営`}</a>{`」宣言を公表しました。経営にデザインを活用した手法や効果、この手法を推進するための政策提言についてまとめられたものとなっており、ご存知の方もいらっしゃると思います。また、ジョン・マエダが発表した「`}<a parentName="p" {...{
        "href": "https://ja.takram.com/projects/design-in-tech-report-2018-translation/"
      }}>{`Design in Tech Report 2018`}</a>{`」などを見ても、特にインターネットテクノロジー業界におけるデザイナーのスタンスに変化が起こって来ているなと感じる昨今でもあります。`}</p>
    <p>{`テクノロジー企業が急成長をしている中でも、特にデザインを強みとした企業が自社の独自性を出しつつ成長しているという状況で、今後どのようなスタイルのデザイナーが求められるのかという事を端的に示しているなと感じました。`}</p>
    <p>{`他方で、インターネット業界でデザインに携わってそこそこの年数を経た私としては、クリエイティブを制作するうえで、デザインとテクノロジーは密接に関わっているという実感があり、常に 2 人 3 脚の関係性でお互い尊重しあいながらプロダクト開発をしてきました。`}</p>
    <p>{`デザイン単体ではなくエンジニアリングと密に関わることでよいプロダクトになるという意識を持っているため、「デザイン」という概念だけで主張する風潮には、すこし違和感を覚える部分があったりします。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181122/20181122161449.png",
        "alt": "f:id:medley_inc:20181122161449p:plain",
        "title": "f:id:medley_inc:20181122161449p:plain"
      }}></img></p>
    <h1>{`メドレーのクリエイティブ文化`}</h1>
    <p>{`メドレーはデザイン部という組織はありますが、プロダクトごとに個々デザイナーが担当しているため、デザイナー同士よりもエンジニアとのコミュニケーションが大半を占めます。`}</p>
    <p>{`一方、プロダクトごとにビジネスモデルの理解や医療に関する知識など、基礎情報をインプットしておくことが重要ですので、十分な理解がないままに安易にペルソナを設計したりせず、事業をしっかり理解したうえでユーザーヒアリングや仮説検証を行うことも重視しています。`}</p>
    <p>{`このようにメドレーのデザイナーは事業理解はもちろんのこと、テクノロジーの領域においても同様に理解をし、共通言語でコミュニケーションを取る必要があります。`}</p>
    <p>{`メドレーのデザイナーは現在 3 名と少数ではありますが、BTC 人材として、それぞれ活躍しています。`}</p>
    <p>{`「BTC 人材」とは、私が入社した当初からデザイナーとして意識している「ビジネス」「テクノロジー」「クリエイティブ」3 軸の領域を横断的に思考してデザインに結びつけるスタンスですが、ただ単に、自分の持つ領域を広げるだけではいいクリエイティブには繋がりません。あくまでデザイナーとしての本来の「軸」を持ちつつ、他の領域に対しても思考を広げていくことが重要なのですが、メドレーのデザイナーはこれを理解し実践しています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181122/20181122161516.png",
        "alt": "f:id:medley_inc:20181122161516p:plain",
        "title": "f:id:medley_inc:20181122161516p:plain"
      }}></img></p>
    <p>{`エンジニアの方も逆に技術だけでなく、事業やクリエイティブへの理解を進め、プロダクトとしての質を高めるために、UI や UX の良し悪しについて我々デザイナーと議論をしています。よく聞くような、実装時にデザインとエンジニア同士で揉めたり、考え方の違いによるミスコミュニケーションに陥るということがありません。`}</p>
    <p>{`より良いプロダクトを作るために、ユーザーに価値をどう提供すべきかお互いの領域に踏み込んでコミュニケーションがとれる文化は、デザイナー・エンジニアを含めたクリエイター組織として強みだと感じています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`このようにメドレーでは自分の専門分野と周辺分野を分け隔てることなく融合し、クリエイティブに結びつけようという文化が根付いています。この文化を一言で表わしているのが「MEDLEY DESIGN & ENGINEERING」という言葉になっているのです。`}</p>
    <p>{`クリエイターページリニューアルの翌日に「`}<a parentName="p" {...{
        "href": "https://drive.medley.jp/"
      }}>{`MEDLEY DRIVE`}</a>{`」のリリースもさせていただきました。このプロジェクトは医療ヘルスケア分野において、インターネットテクノロジーの活用を推進するための支援を目的としたプロジェクトです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181122/20181122161531.png",
        "alt": "f:id:medley_inc:20181122161531p:plain",
        "title": "f:id:medley_inc:20181122161531p:plain"
      }}></img></p>
    <p>{`この MEDLEY DRIVE というプロジェクトを通して、医療の課題に対して、デザイナーがもっと活躍していく機会が増えていくと思うとワクワクします。`}</p>
    <p>{`実際問題、カルテなどの医療システムは業務の性質上、複雑な構成になりやすかったりするのですが、そこにデザイナーが介入することによって医療機関や患者に対して、より価値あるプロダクトを推進していける分野でもあると思っています。`}</p>
    <p>{`医療の課題に向き合って価値のあるプロダクトを創出したい方はもちろん、現在医療に興味がない方でも、気軽に弊社に遊びにきて話をしてみませんか？`}</p>
    <p>{`お知らせ
メドレーでは、医療介護の人材採用システム「ジョブメドレー」や、医師たちがつくるオンライン医療事典「MEDLEY」、医療につよい介護施設・老人ホームの検索メディア「介護のほんね」、オンライン診療アプリ「CLINICS」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`ちょっと興味があるという方も、ぜひお気軽にご連絡ください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <span style={{
      "fontWeight": "400"
    }}>&nbsp;</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      